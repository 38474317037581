import Vue from 'vue'
import axios from 'axios'
import router from '../../router'

export default {
  state: {
    theme: {
      name: null,
      images: null
    },
    themeLoading: false
  },
  mutations: {
    setThemeName(state, data) {
      state.theme.name = data
    },
    setThemeImages(state, data) {
      state.theme.images = data
    },
    setThemeLoading(state, data) {
      state.themeLoading = data
    },
  },
  getters: {
    themeName(state) {
      return state.theme.name
    },
    themeImages(state) {
      return state.theme.images
    },
    isThemeLoading(state) {
      return state.themeLoading
    }
  },
  actions: {
    setTheme({ commit }, theme) {
      commit('setThemeName', theme)
      if (theme === "aire") {
        commit('setThemeImages', { mainLogo: 'img/logo-zentinela.svg', altLogo: 'img/logo-zentinela.svg', shield: "img/aire-shield.svg", brand: "img/brand-aire.svg" })
      } else {
        commit('setThemeImages', { mainLogo: 'img/be_safe_smart.svg', altLogo: 'img/be_safe_smart_white.svg', shield: "img/levels/high-selected.png" })
      }
    },
    async fetchTheme({ commit, state }) {
      commit('setThemeLoading', true)
      var path = `/environment_settings/gui_theme`;
      var response = await axios.get(path)
      var theme = 'teldat'
      if (response.status == "200") {
        theme = response.data.gui_theme
      } else {
        console.error("error loading theme", response);
      }
      commit('setThemeName', theme)
      if (theme === "aire") {
        commit('setThemeImages', { mainLogo: 'img/logo-zentinela.svg', altLogo: 'img/logo-zentinela.svg', shield: "img/aire-shield.svg", brand: "img/brand-aire.svg" })
      } else {
        commit('setThemeImages', { mainLogo: 'img/be_safe_smart.svg', altLogo: 'img/be_safe_smart_white.svg', shield: "img/levels/high-selected.png" })
      }
      commit('setThemeLoading', false)
    }
  },
}
